import {
  AppchainIdenticon,
  Props as IdenticonProps,
} from '@/components/AppchainIdenticon';
import { ChainConfig } from '@/config';
import { useAppchain } from '@/hooks/api/useAppchain';
import { Group, Loader, Text, useMantineTheme } from '@mantine/core';
import { FadeOutText } from '../FadeOutText';

export interface Props {
  paraId: number;
  config: ChainConfig;
  identiconProps?: Partial<IdenticonProps>;
  variant?: 'yellow' | 'blue';
  withName?: boolean;
}

export function AppchainInfo({
  paraId,
  config,
  identiconProps,
  variant = 'yellow',
  withName = true,
}: Props) {
  const theme = useMantineTheme();
  const { appchain, isLoading } = useAppchain(config.key, paraId);

  if (isLoading) {
    return <Loader color={theme.colors.tanssiTeal[9]} />;
  }

  const displayname =
    paraId === config.parachainId
      ? config.name
      : paraId === config?.relay.parachainId
        ? config.relay.name
        : appchain?.chainName || 'Appchain';

  return (
    <Group gap={'xs'}>
      <AppchainIdenticon
        logoUrl={appchain?.logoUrl}
        paraId={paraId}
        {...identiconProps}
      />
      {withName && (
        <FadeOutText maxWidth={180}>
          <Text
            size={'sm'}
            fw={500}
            c={
              variant === 'yellow'
                ? theme.other.colors.yellow
                : theme.colors.tanssiTeal[9]
            }
          >
            {displayname}:
          </Text>
        </FadeOutText>
      )}
      <Text size={'sm'}>{paraId}</Text>
    </Group>
  );
}

import { useAddress } from '@/state/user';
import { trpc } from '@/utils/trpc';
import { useDidUpdate } from '@mantine/hooks';

export function useUserAppchains() {
  const address = useAddress();
  const { isLoading, data, refetch } = trpc.appchains.getUserAppchains.useQuery(
    undefined,
    {
      enabled: !!address,
    },
  );

  useDidUpdate(() => {
    if (address) refetch();
  }, [address]);

  return {
    isLoading,
    appchains: data,
  };
}

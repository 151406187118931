import { Dashboard } from '@/components/pages/Dashboard';
import { Landing } from '@/components/pages/Landing';
import { useUserAppchains } from '@/hooks/api/useUserAppchains';
import { useAddress } from '@/state/user';
import { Center, Loader } from '@mantine/core';

export default function HomePage() {
  const address = useAddress();
  const { appchains, isLoading } = useUserAppchains();

  if (isLoading || (address && !appchains)) {
    return (
      <Center
        pos={'absolute'}
        top={'50vh'}
        left={'50vw'}
        style={{ transform: 'translate(-50%, -50%)' }}
      >
        <Loader size={50} />
      </Center>
    );
  }

  if (!isLoading && !appchains?.length) {
    return <Landing />;
  }

  return <Dashboard />;
}

import {
  useCreateAppchainReset,
  useUserChainDataState,
} from '@/components/pages/CreateAppChain/state/create.hooks';
import { ChainKey } from '@/config';
import { useChainKey } from '@/state/chain';
import { isDefined } from '@/utils/common';
import { Box, Group, Stack, Text, Title, UnstyledButton } from '@mantine/core';
import { modals } from '@mantine/modals';
import { IconArrowNarrowRight } from '@tabler/icons-react';
import { useRouter } from 'next/router';
import classes from './SelectChain.module.css';

interface Props {
  isDisabled: boolean;
  chainKey: ChainKey;
  titleChainType: string;
  description: string;
  openModal: () => void;
  setChainKey: (chainKey: ChainKey) => void;
}

export function SelectChainCard({
  isDisabled,
  chainKey,
  titleChainType,
  description,
  setChainKey,
}: Props) {
  const key = useChainKey();
  const { push } = useRouter();
  const chain = titleChainType.toLowerCase();
  const { chainData } = useUserChainDataState();
  const resetCreateAppchain = useCreateAppchainReset();

  const openModal = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    modals.openConfirmModal({
      size: 'lg',
      title:
        key === chainKey
          ? 'Continue Creating Appchain?'
          : 'Confirm New Appchain Creation',
      children: (
        <Text c={'gray.5'} mb={'xl'}>
          {`You have an existing ${
            key === ChainKey.Flashbox ? 'Snap' : 'Dedicated'
          } Appchain creation in progress. If you proceed with creating a new Appchain, your current progress will be lost.`}
        </Text>
      ),
      labels: {
        confirm: 'Continue Process',
        cancel: 'Create New Appchain',
      },
      onConfirm: async () => {
        await push(`/${key}/create`);
        setChainKey(key);
      },
      onCancel: async () => {
        resetCreateAppchain(true);
        await push(`/${chainKey}/create`);
        setChainKey(chainKey);
      },
    });
  };

  return (
    <UnstyledButton
      className={`${classes.card} ${classes[chain]}`}
      onClick={
        isDisabled
          ? undefined
          : (e) => {
              if (isDefined(chainData)) {
                openModal(e);
              } else {
                setChainKey(chainKey);
                push(`/${chainKey}/create`);
              }
            }
      }
      data-testid={`${chain}-chain`}
      style={{
        flex: 1,
        minWidth: 300,
        height: '100%',
        opacity: isDisabled ? 0.5 : 1,
      }}
    >
      <Stack w={'100%'} h={'100%'} justify={'space-between'}>
        <Title order={2} size={35} c={'white'} fw={700}>
          {`Launch a ${titleChainType}`}
          <br />
          {'Appchain'}
        </Title>
        <Group justify={'space-between'} align={'end'} wrap={'nowrap'} gap={20}>
          <Text>{description}</Text>
          <Box>
            <IconArrowNarrowRight
              size={40}
              stroke={1.5}
              style={{ alignSelf: 'end' }}
            />
          </Box>
        </Group>
      </Stack>
    </UnstyledButton>
  );
}

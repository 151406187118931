import { ActionButton } from '@/components/ActionButton';
import { Alert } from '@/components/Alert';
import { useIsExplorerRequested } from '@/components/AppchainCard/Alerts/state/Alerts.hooks';
import { useManageAppchain } from '@/components/pages/Dashboard/ManageAppchain/state';
import { ManageAppchainTab } from '@/components/pages/Dashboard/ManageAppchain/state/ManageAppchain.constants';
import { ChainConfig, chains } from '@/config';
import { useIsBlockscoutTrialEnabled } from '@/hooks/flags/useIsBlockscoutTrialEnabled';
import { AppChainStatus, useAppchainStatus } from '@/hooks/polkadot/appchain';
import { AppchainItem } from '@/server/router/appchains';
import { Group, Text } from '@mantine/core';

interface Props {
  appchain: AppchainItem;
}

export function ExplorerAlert({ appchain }: Props) {
  const config = chains.get(appchain.chainKey) as ChainConfig;
  const { status } = useAppchainStatus(appchain.paraId, config);
  const { isEnabled } = useIsBlockscoutTrialEnabled();
  const { isExplorerRequested } = useIsExplorerRequested();
  const { openAppchainSidebar } = useManageAppchain();

  const shouldShowAlert =
    status === AppChainStatus.Online && !isExplorerRequested && isEnabled;

  if (!shouldShowAlert) {
    return null;
  }

  return (
    <Alert
      mt={15}
      px={'md'}
      h={66}
      bg={'dark.8'}
      color={'tanssiTeal.9'}
      style={{ border: 'none', borderRadius: 20 }}
    >
      <Group justify="space-between" w={'100%'}>
        <Text size="xs" fw={600}>
          Request Blockscout Trial
        </Text>
        <ActionButton
          h={35}
          miw={'auto'}
          size="xs"
          bg={'tanssiTeal.9'}
          withArrow={false}
          onClick={() => {
            openAppchainSidebar({
              paraId: appchain.paraId,
              config,
              tab: ManageAppchainTab.Explorers,
            });
          }}
        >
          Request
        </ActionButton>
      </Group>
    </Alert>
  );
}
